import axios from 'axios';
import missionController from './mission_controller';
import { POST_CONFIG } from '../../../common/helpers';

const LOCAL_STORAGE_KEY = 'missionCenterData';

class MissionCenterData {
    // Initialize config from server data
    #config = PCH?.surveys?.missionCenter;
    // Deserialize local data from localStorage
    #localData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
    // Initialize api data
    #latestData = {};

    changedAlias = null;
    firstAvailable = null;

    constructor() {
        if(!this.#config) {
            console.warn('Mission Center config not found');
            return;
        }

        // If there is locally stored step info, prune away any steps from inactive mission groups
        const activeAliases = this.#config.mission_groups.map(missionGroup => missionGroup.mission_alias);
        if(this.#localData) {
            const localAliases = Object.keys(this.#localData);
            localAliases.forEach(alias => { 
                if(!activeAliases.includes(alias)) {
                    delete this.#localData[alias]
                }
            });
        }

        // Get the latest step data for each mission group
        const apiData = {};
        for(let i = 0; i < this.#config.mission_groups.length; i++) {
            const missionGroup = this.#config.mission_groups[i];

            const alias = missionGroup.mission_alias;
            const apiPayload = {
                "device":PCH.ssoui,
                "missionAlias":alias,
                "sessionToken":PCH.surveys.userInfo.sessionToken,
                "ref":PCH.LogReference,
                "gmt":PCHUSER.gmt,
                "oat":PCHUSER.oat,
            }

            apiData[alias] = axios.post(this.#config.steps_api_url, apiPayload, POST_CONFIG)
                // Get the data.data
                .then(response => response.data )
                // Check the api status first
                .then(data => data.status === 'success' ? data.data : Promise.reject(data))
                // Update data
                .then(data => {
                    this.updateLocalStorageByAlias(alias, data, i);

                    // Cache the API response data to compare with localStorage data.
                    this.#latestData[alias] = data;

                    return data;
                })
                .catch(error => {
                    console.warn(error);
                    if(this.#localData[alias]) {
                        delete this.#localData[alias];
                    }
                    missionController.publish('mission-error', alias);
                });
        }

        Promise.all(Object.values(apiData)).then(() => {
            this.changedAlias = this.getAliasWithLatestCompletion();
            if(this.changedAlias) {
                this.changedStep = this.getLatestCompletedStepByAlias(this.changedAlias);
                this.stepsUnlocked = this.getLatestUnlockedStepsByAlias(this.changedAlias);
            }
            this.firstAvailable = this.getFirstAliasWithAvailability();

            // Serialize the local data to localStorage
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.#localData));
            missionController.subscribe('feedback-complete', () => {
                // If things changed, serialize the latest data to localStorage
                if(this.changedAlias) localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.#latestData));

                // Regardless, announce to the system that feedback is done here
                setTimeout(() => {  // But just pause first to give the UI a chance to update
                    document.body.dataset.missionFeedback = 'done';
                }, 1000);
            });
            missionController.publish('data-ready', this);
        });
    }

    /**
     * Update a few specific things based on the api data.
     * 
     * @param {string} alias The mission alias
     * @param {object} newData The new data retrieved from the API
     * @param {number} ordinal The index of the alias according to the mission group order in PCH.surveys.missionCenter.mission_groups
     */
    updateLocalStorageByAlias(alias, newData, ordinal) {
        // Add new missions groups that were not previously saved in local storage
        if(!this.#localData[alias]) {
            this.#localData[alias] = newData;
        }

        // Remember mission group order
        this.#localData[alias].ordinal = ordinal;

        // Update mission end timestamps
        this.#localData[alias].info.mission_end_datetime = newData.info.mission_end_datetime;

        for(let i = 0; i < this.#localData[alias].steps.length; i++) {
            const localStep = this.#localData[alias].steps[i];
            const newStep = newData.steps[i];

            // Update links for steps transitioning from "locked" to "available"
            if(localStep.status === 'locked' && newStep.status === 'available') {
                localStep.link = newStep.link;
            }

            // If any steps change from "complete" to "available" or "locked", we assume the mission has reset, just update the status immediately.
            if(localStep.status === 'complete' && newStep.status !== 'complete') {
                localStep.status = newStep.status;
            }
        }
    }
    
    getLocalStepsByAlias(alias) {
        return this.#localData[alias]?.steps || [];
    }

    getGroupConfigByAlias(alias) {
        return this.#config.mission_groups.find(missionGroup => missionGroup.mission_alias === alias);
    }

    getMissionEndByAlias(alias) {
        return this.#localData[alias]?.info?.mission_end_datetime;
    }

    getCompletedStepCountByAlias(alias) {
        if(!this.#localData[alias]) return 0;
        return this.#localData[alias].steps.filter(step => step.status === 'complete').length;
    }

    getLatestCompletedStepCountByAlias(alias) {
        if(!this.#latestData[alias]) return 0;
        return this.#latestData[alias].steps.filter(step => step.status === 'complete').length;
    }

    getLatestCompletedStepByAlias(alias) {
        for(let i = 0; i < this.#localData[alias].steps.length; i++) {
            const localStep = this.#localData[alias].steps[i];
            const latestStep = this.#latestData[alias].steps[i];

            if(localStep.status === 'available' && latestStep.status === 'complete') {
                return i;
            }   
        }
    }

    getLatestUnlockedStepsByAlias(alias) {
        const unlockedSteps = [];
        for(let i = 0; i < this.#localData[alias].steps.length; i++) {
            const localStep = this.#localData[alias].steps[i];
            const latestStep = this.#latestData[alias].steps[i];

            if(localStep.status === 'locked' && latestStep.status === 'available') {
                unlockedSteps.push(i);
            }   
        }
        return unlockedSteps;
    }

    getTotalStepCountByAlias(alias) {
        if(!this.#localData[alias]) return 0;
        return this.#localData[alias].steps.length;
    }

    getMissionRewardTypesByAlias(alias) {
        if(!this.#localData[alias]) return [];
        return this.#localData[alias].info.reward_types;
    }

    getAliasWithLatestCompletion() {
        const aliases = Object.keys(this.#latestData);
        for(let i = 0; i < aliases.length; i++) {
            const alias = aliases[i];
            const fromCount = this.getCompletedStepCountByAlias(alias);
            const toCount = this.getLatestCompletedStepCountByAlias(alias);

            if(fromCount < toCount) {
                return alias;
            }
        }
    }

    getFirstAliasWithAvailability() {
        const sortedLocalDataArray = Object.entries(this.#localData).sort((a, b) => a[1].ordinal - b[1].ordinal);

        for(let i = 0; i < sortedLocalDataArray.length; i++) {
            const alias = sortedLocalDataArray[i][0];
            const steps = this.getLocalStepsByAlias(alias);
            const firstAvailableStep = steps.find(step => step.status === 'available');

            if(firstAvailableStep) {
                return alias;
            }
        }
    }

    get config() {
        return this.#config;
    }
}

new MissionCenterData();

export default MissionCenterData;